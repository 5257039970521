<template>
  <div class="one-column-width">
    <!-- show action result -->
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>{{ snackBarText }}</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" absolute top right color="danger">
      <span>Something went RONG, Please Try Again!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline">Do you really want to DELETE this item?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false">No</v-btn>
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner>Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- form start -->
    <v-card class="add-form-title" v-if="showFormData">
      <v-card-title> Add New Form Template </v-card-title>
      <div>
        <v-form ref="form" @submit.prevent="submit" class="custom-form">
          <v-container fluid>
            <v-col cols="12" sm="12">
              <v-row v-for="(item, index) in form" :key="index">
                <v-text-field
                  v-model="item.name"
                  :rules="rules.name"
                  color="purple name-2"
                  label="Name"
                  class="col-md-3"
                  required
                ></v-text-field>
                <v-autocomplete
                  v-model="item.tab"
                  :rules="rules.tab"
                  :items="tabsData"
                  label="Select tab"
                  class="col-md-3"
                  multiple
                ></v-autocomplete>
<p>Default</p>&nbsp;&nbsp;&nbsp;
                <v-radio-group v-model="item.default" row class="col-md-3">
                    
                  <v-radio label="Yes " value=1></v-radio>
                  <v-radio label="No" value=0></v-radio>
                </v-radio-group>

                <template v-if="!editId">
                  <v-btn
                    class="custom-add-new-record-button"
                    type="button"
                    @click="addNewRow()"
                    >+</v-btn
                  >
                  <v-btn
                    v-if="index > 0"
                    class="custom-delete-new-record-button"
                    type="button"
                    @click="deleteRow(index)"
                    >-</v-btn
                  >
                </template>
              </v-row>
            </v-col>
          </v-container>
          <v-card-actions>
            <v-btn
              :disabled="$v.$invalid || submitting"
              class="custom-submit-button"
              type="submit"
              ><b-spinner small type="grow" v-if="submitting"></b-spinner>
              <span v-if="!editId">Submit</span>
              <span v-if="editId">Update</span>
            </v-btn>
            <v-btn text @click="cancelForm" class="custom-cancel-button">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
    <!-- form end -->

    <!-- table start -->
    <v-card>
      <v-card-title>
        Admission Form Templates
        <div class="my-2 ml-2">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showForm"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search templates"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showEditForm(item)"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="askUserAboutDelete(item)"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import AdmissionForm from "@/MainServices/AdmissionForm.js";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: [
        {
          tab: [],
          name: "",
          default: "",
        },
      ],
      editId: null,
      deleteId: null,
      dialog: false,
      rules: {
        tab: [(val) => !!val || "Tab is required"],
        name: [(val) => (val || "").length > 0 || "Name is required"],
        default: [(val) => (val || "").length > 0 || "This Option is required"],
      },
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
          width: "20%",
        },
        {
          text: "Tabs",
          align: "left",
          sortable: false,
          value: "tab_info",
          width: "60%",
        },

        {
          text: "Default",
          align: "left",
          sortable: false,
          value: "default",
          width: "10%",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
      tabsData: [],
    };
  },
  validations: {
    form: {
      required,
      $each: {
        tab: { minLength: minLength(1), required },
        name: { minLength: minLength(1), required },
        default: { minLength: minLength(1), required },
      },
    },
  },
  components: {},

  computed: {},

  methods: {
    getInititalData() {
      AdmissionForm.getForm()
        .then(res => {
          this.tableData = res.data;
          
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    showForm() {
      this.editId = null;
      this.showFormData = true;
      this.resetForm();
    },
    showEditForm(item) {
      this.form = [
        {
          tab: item.tab,
          name: item.name,
          default: item.default,
        },
      ];
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.resetForm();

      this.showFormData = false;
    },
    resetForm() {
      this.form = [
        {
          tab: [],
          name: "",
          default: "",
        },
      ];
      this.editId = null;
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },
    addNewRow() {
      this.form.push({
        tab: [],
        name: "",
        default: "",
      });
    },
    deleteRow(index) {
      this.form.splice(index, 1);
    },
    submit() {
      this.submitting = true;
      if (this.editId) {
        AdmissionForm.editForm(this.form, this.editId)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Fields Updated successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      } else {
        AdmissionForm.addNewForm(this.form)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Fields Added successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      }
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      AdmissionForm.deleteForm(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Field Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
    AdmissionForm.getFormTabs()
      .then((res) => {
        res.data.map((el) => {
          this.tabsData.push({ value: el.id, text: el.name });
        });
      })
      .catch((e) => {});
  },
};
</script>
